<script>
import Swal from "sweetalert2";
import axios from "axios";
import SelectCompany from "@/components/custom/SelectCompany.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import moment from "moment";

export default {
  name: "paymentExcelUpload",
  data() {
    return {
      showModal: false,
      file: null,
      requested_data: {
        is_loading: false,
        ok: false,
        data: []
      },
    };
  },
  components: {
    SelectCompany,
    Multiselect
  },
  methods: {
    openModal() {
      this.requested_data = {
        is_loading: false,
        ok: false,
        data: []
      };
      this.file = null;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
      this.showModal = true;
    },
    onUpload(event) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      const allowedTypes = ['xls', 'xlsx'];

      if (allowedTypes.includes(fileType)) {
        this.file = file;
      } else {
        this.$refs.fileInput.value = '';
        Swal.fire({
          icon: 'error',
          title: 'Invalid file type',
          text: 'Please upload an Excel file',
        });
      }
    },
    async requestExcelData() {
      this.requested_data.is_loading = true;
      try {
        let formData = new FormData();
        formData.append('excel_file', this.file);
        let response = await axios.post('/customer/customer_payment_excel/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.requested_data.data = response.data.map(data => {
          return {
            ...data,
            _contract: null
          }
        });
        this.requested_data.ok = true;
        this.requested_data.is_loading = false;
      } catch (error) {
        this.requested_data.ok = false;
        this.requested_data.is_loading = false;
        try {
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error ? error.response.data.error : 'Error while reading excel file',
          })
        } catch {
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error while reading excel file',
          })
        }
      }
    },

    async onCompanySelect(company, data) {
      data._company = company ? company : undefined;
      await this.getContractsByCustomer(company, data)
    },

    async getContractsByCustomer(company, data) {
      if (company && company.slug) {
        try {
          let response = await axios.get(`/contract/${company.slug}/by_company/`);
          data._contract_options = (response.data.results || []).map((contract) => {
            return {
              value: contract.id,
              label: contract.name,
              slug: contract.slug,
              start_date: contract.start_date,
              expire_date: contract.expire_date,
            }
          });
          if (data._contract_options.length === 1) {
            data._contract = data._contract_options[0];
          }
        } catch {
          data._contract_options = [];
        }
      } else {
        console.log('Failed to load contracts!');
      }
    },
    async submitForm() {
      if (!this.allHaveCompany) return;

      try {
        let formData = new FormData();
        formData.append('excel_file', this.file);

        this.requested_data.data.forEach((data, index) => {
          formData.append(`payments[${index}][contract_id]`, data._contract.value);
          formData.append(`payments[${index}][date]`, data.date);
          formData.append(`payments[${index}][amount]`, data.debit || 0);
        });

        let response = await axios.post('/customer/customer_payment_create_from_excel/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        response.data.forEach((data) => {
          try {
            this.requested_data.data.find((item) =>
                moment(item.date, "DD.MM.YYYY").format("YYYY-MM-DD") === data.date
                && item._contract.value.toString() === data.contract_id
                && (item.debit || 0).toString() === (data.amount || 0).toString()
            ).ok = data.status === 'created';
          } catch {
            console.log("hh")
          }
        });

        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Payments created successfully',
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error while submitting the form',
        })
      }
    }
  },
  computed: {
    allHaveCompany() {
      return this.requested_data.data.every(item => item._company && item._contract)
    }
  },
  watch: {
    file: {
      handler: function (newValue) {
        if (newValue) {
          this.requestExcelData()
        }
      },
      deep: true,
    },
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Upload Payment Excel" hide-footer centered size="xl">
    <b-form @submit.prevent="submitForm()">

      <!-- Info Alert -->
      <b-alert show variant="secondary" class="alert-label-icon label-arrow fade show mb-3" role="alert">
        <i class="ri-airplay-line label-icon"></i><strong>Attention</strong> - This works only for customer payments.
      </b-alert>

      <div v-if="!file">
        <label class="form-label">Select an excel file to upload</label>
        <input ref="fileInput" @change="onUpload" type="file" class="form-control" accept=".xls,.xlsx" required>
      </div>
      <div v-else>
        <b-spinner v-if="requested_data.is_loading" label="Loading..."></b-spinner>
        <div v-else>
          <div v-if="requested_data.ok">
            <div class="table-responsive">
              <table class="table table-bordered table-nowrap">
                <thead>
                <tr class="align-middle">
                  <th scope="col">#</th>
                  <th scope="col">Company</th>
                  <th scope="col">Select company</th>
                  <th scope="col">Select contract</th>
                  <th scope="col">Date</th>
                  <th scope="col">Credit</th>
                  <th scope="col">Debit</th>
                  <th scope="col">Balance</th>
                </tr>
                </thead>
                <tbody>
                <tr class="align-middle "
                    :class="{
                  'bg-soft-success': data.ok,
                  'bg-soft-danger': data.ok === false
                    }"
                    v-for="(data, index) in requested_data.data" :key="`payment_data_${data}`">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    {{ data.company.split('\n')[0] }},<br>
                    {{ data.company.split('\n')[1] }},<br>
                    {{ data.company.split('\n')[2] }}
                    <div v-if="data._company" class="mt-2">
                      <h6 class="mb-0 text-dark fw-medium">{{ data._company.label }}</h6>
                    </div>
                  </td>
                  <td style="min-width: 180px">
                    <SelectCompany @onSelect="onCompanySelect($event, data)"/>
                  </td>
                  <td style="min-width: 150px">
                    <Multiselect v-model="data._contract" v-bind="{
                          class: `form-control`,
                          options: data._contract_options,
                          placeholder: `Select a contract`,
                          object: true,
                          searchable: true,
                    }"
                    />
                  </td>
                  <td>{{ data.date }}</td>
                  <td>{{ data.credit }}</td>
                  <td>{{ data.debit }}</td>
                  <td>{{ data.balance }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <b-button class="w-100" type="submit" variant="primary"
                        :disabled="!allHaveCompany || requested_data.is_loading">
                {{ requested_data.is_loading ? 'Loading...' : 'Save' }}
              </b-button>
            </div>
          </div>
          <div v-else>
            <!-- Danger Alert -->
            <b-alert show variant="danger" class="border-0 " role="alert">
              <strong> Oops! </strong> Something went wrong while reading the excel file.
            </b-alert>
            <b-button variant="primary" @click="openModal()">Try again</b-button>
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>
  <b-button variant="secondary" @click="openModal()">Upload Payment Excel</b-button>
</template>

<style scoped>

</style>